@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/inter.ttf');
}

@font-face {
  font-family: 'linear-sans';
  src: url('./assets/fonts/linearsans-regular.otf');
}
@font-face {
  font-family: 'BigCaslon';
  src: url('./assets/fonts/BigCaslon.ttf');
}

@font-face {
  font-family: 'BigCaslon-Italic';
  src: url('./assets/fonts/BigCaslonFB-Italic.ttf');
}

@font-face {
  font-family: 'Whisper-Regular';
  src: url('./assets/fonts/Whisper-Regular.ttf');
}


#root {
  height: 100%;
}

input[type='radio'] {
  accent-color: #4228b2;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body {
  font-family: Inter, BigCaslon, BigCaslon-Italic, Whisper-Regular, sans-sarif ;
}

.rs-picker-daterange .rs-input-group {
  background-color: #efe8df !important;
  height: 100%;
}

.rs-picker-daterange .rs-input-group:focus {
  border: none;
  border-color: transparent !important;
}
.rs-picker-daterange .rs-input-group:focus-within {
  border: none;
  border-color: transparent !important;
}
.rs-picker-daterange .rs-input-group .rs-date-range-input {
  background-color: #efe8df !important;
  height: 100%;
}

.rs-picker-daterange .rs-input-group .rs-date-range-input:focus {
  border: none;
  border-color: transparent !important;
}

.css-b62m3t-container .select-drop-arrow__control .select-drop-arrow__value-container {
  display: flex;
}

.autocomplete-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: #efe8df !important;
  border-radius: 5px;
}

.autocomplete-wrapper {
  position: relative;
  width: 100%;
}

.autocomplete-input {
  width: 100%;
  box-sizing: border-box;
  height: 50px;
  background-color: #efe8df !important;
  border-radius: 5px;
  padding-left: 40px;
  height: 60px;
  font-style: normal;
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  outline: none;
  color: #4a4a4a;
}
.autocomplete-input:focus-visible {
  outline: none;
}
.autocomplete-input::placeholder {
  color: #4a4a4a;
  font-size: 18px;
  font-family: 'Inter';
  font-weight: 300;
  line-height: 24px;
}

.custom-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  pointer-events: none; /* Ensure the icon doesn't interfere with clicking on the input */
}

.autocomplete-suggestions {
  list-style: none;
  /* margin: 0; */
  margin-top: 8px !important;
  border-radius: 5px !important;
  padding: 10 0px;
  /* border: 1px solid #ccc; */
  border-top: none;
  /* max-height: 200px; */
  overflow-y: auto;
  background-color: #efe8df;
  position: absolute;
  width: 100%;
  z-index: 1000;
}

.autocomplete-suggestion {
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.autocomplete-suggestion:hover {
  /* background-color: #f0f0f0; */
}

.suggestion-main {
  /* font-weight: bold; */
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 300;
  line-height: 21.6px;
  color: #000000;
  margin-right: 10px;
  padding-bottom: 6px;
}

.suggestion-secondary {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 300;
  line-height: 16.8px;
  color: #4a4a4a;
  margin: 0;
}

.selected-place-details {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  width: 90%;
  background-color: #fff;
}

.custom-icon {
  margin-right: 10px;
}

@media (min-width: 640px) and  (max-width:1100px) {
  .autocomplete-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 40px;
    background-color: #efe8df !important;
    border-radius: 5px;
  }
  .autocomplete-input {
    width: 100%;
    box-sizing: border-box;
    height: 50px;
    background-color: #efe8df !important;
    border-radius: 5px;
    padding-left: 40px;
    height: 40px;
    font-style: normal;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 300;
    line-height: 16.8px;
    outline: none;
    color: #4a4a4a;
  }

  .autocomplete-input::placeholder {
    color: #4a4a4a;
    font-size: 14px;
    font-family: 'Inter';
    font-weight: 300;
    line-height: 16.8px;
  }
  .suggestion-main {
    /* font-weight: bold; */
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 300;
    line-height: 16.8px;
    color: #000000;
    margin-right: 10px;
    padding-bottom: 6px;
  }
  
  .suggestion-secondary {
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 300;
    line-height: 16.8px;
    color: #4a4a4a;
    margin: 0;
  }
}
@media (min-width: 320px) and  (max-width: 640px) {
  .autocomplete-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 40px;
    background-color: #efe8df !important;
    border-radius: 5px;
  }
  .autocomplete-input {
    width: 100%;
    box-sizing: border-box;
    height: 50px;
    background-color: #efe8df !important;
    border-radius: 5px;
    padding-left: 40px;
    height: 40px;
    font-style: normal;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 300;
    line-height: 16.8px;
    outline: none;
    color: #4a4a4a;
  }

  .autocomplete-input::placeholder {
    color: #4a4a4a;
    font-size: 14px;
    font-family: 'Inter';
    font-weight: 300;
    line-height: 16.8px;
  }
  .suggestion-main {
    /* font-weight: bold; */
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 300;
    line-height: 16.8px;
    color: #000000;
    margin-right: 10px;
    padding-bottom: 6px;
  }
  
  .suggestion-secondary {
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 300;
    line-height: 16.8px;
    color: #4a4a4a;
    margin: 0;
  }
}

/* Adjust the styles for the slider item and text overlay */
.slick-slider-container-home .slider-item {
  position: relative;
  display: flex;
  justify-content: center;
}

.slick-prev {
  width: 60px !important;
  height: 25px !important;
  z-index: 50;
}

.slick-next {
  width: 60px !important;
  height: 25px !important;
  z-index: 50;
}

.slick-slider-container-home .text-overlay {
  position: absolute;
  top: 20px; /* Adjust top position as needed */
  left: 20px; /* Adjust left position as needed */
  background-color: #3e7a63;
  font-family: 'Inter';
  color: #efe8df;
  padding: 5px 31px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 300;
  border-radius: 10px;
}

/* Centered item styles */
.slick-slider-container-home .slick-center .text-overlay {
  display: block;
}

/* Hide text overlay for non-centered items */
.slick-slider-container-home .slick-slide:not(.slick-center) .text-overlay {
  display: none;
}

.slick-slider-container-home .bottom-text-overlay {
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #c56b48;
  font-family: 'Inter';
  color: #efe8df;
  padding: 10px 39px;
  border-radius: 5px;
  font-size: 28px;
  font-weight: 500;
  border-radius: 10px;
  text-align: center;
  z-index: 9999;
}

.slick-slider-container-home .slick-center .bottom-text-overlay {
  display: block;
}

.slick-slider-container-home .slick-slide:not(.slick-center) .bottom-text-overlay {
  display: none;
}
.slick-slider-container-home .slick-center .rate-and-total-hotels {
  display: block;
}

.slick-slider-container-home .slick-slide:not(.slick-center) .rate-and-total-hotels {
  display: none;
}

.slick-slider-container-home .slick-center > div > div img {
  height: 460px;
  width: 740px;
  border-radius: 10px;
}

.slick-slider-container-home .slick-slide:not(.slick-center) {
  display: flex;
  justify-content: center;
}
.slick-slider-container-home .slick-slide:not(.slick-center) > div > div img {
  /* height: 265px;
  width: 265px; */
  width: 79%;
  height: 100%;
  border-radius: 10px;
}

.slick-slider-container-home .slick-track {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
}

@media (min-width: 640px) and  (max-width:1100px) {
  .slick-slider-container-home .slick-center {
    width: 300px !important;
  }

  .slick-slider-container-home .slider-item {
    display: flex;
    justify-content: center;
  }

  .slick-prev {
    width: 38px !important;
    height: 16px !important;
  }

  .slick-next {
    width: 38px !important;
    height: 16px !important;
  }

  .slick-slider-container-home .slick-center .text-overlay {
    display: flex;
  }
  .slick-slider-container-home .text-overlay {
    position: absolute;
    top: -16px; /* Adjust top position as needed */
    left: 50%; /* Adjust left position as needed */
    transform: translateX(-50%);
    background-color: #3e7a63;
    font-family: 'Inter';
    color: #efe8df;
    /* padding: 5px 24px; */
    width: 178px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 300;
    border-radius: 10px;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
  }

  .slick-slider-container-home .bottom-text-overlay {
    position: absolute;
    bottom: -16px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #c56b48;
    font-family: 'Inter';
    color: #efe8df;
    /* padding: 6px 47px; */
    width: 171px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.8px;
    border-radius: 10px;
    text-align: center;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .slick-slider-container-home .slick-center > div > div img {
    height: 227px;
    width: 300px;
  }

  @media (min-width: 640px) and  (max-width:680px) {
    .slick-slider-container-home .slick-slide:not(.slick-center) > div > div img {
      height: 110px;
      width: 111px;
    }
  }
  @media (min-width: 681px) and  (max-width:730px) {
    .slick-slider-container-home .slick-slide:not(.slick-center) > div > div img {
      height: 130px;
      width: 131px;
    }
  }
  @media (min-width: 731px) and  (max-width:760px) {
    .slick-slider-container-home .slick-slide:not(.slick-center) > div > div img {
      height: 140px;
      width: 141px;
    }
  }
  @media (min-width: 761px) and  (max-width:800px) {
    .slick-slider-container-home .slick-slide:not(.slick-center) > div > div img {
      height: 155px;
      width: 156px;
    }
  }

  @media (min-width: 850px) and  (max-width:900px) {
    .slick-slider-container-home .slick-slide:not(.slick-center) > div > div img {
      height: 170px;
      width: 171px;
    }
  }
  @media (min-width: 901px) and  (max-width:950px) {
    .slick-slider-container-home .slick-slide:not(.slick-center) > div > div img {
      height: 170px;
      width: 181px;
    }
    .slick-slider-container-home .slick-center > div > div img {
      height: 247px;
      width: 340px;
    }
  }
  @media (min-width: 951px) and  (max-width:1000px) {
    .slick-slider-container-home .slick-slide:not(.slick-center) > div > div img {
      height: 180px;
      width: 201px;
    }
    .slick-slider-container-home .slick-center > div > div img {
      height: 247px;
      width: 320px;
    }
  }
  @media (min-width: 1001px) and  (max-width:1100px) {
    .slick-slider-container-home .slick-slide:not(.slick-center) > div > div img {
      height: 180px;
      width: 221px;
    }
    .slick-slider-container-home .slick-center > div > div img {
      height: 247px;
      width: 320px;
    }
  }

  .slick-slider-container-home .slick-track {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
}

@media (max-width: 320px) {
  .slick-slider-container-home .slick-center {
    width: 300px !important;
  }

  .slick-slider-container-home .text-overlay {
    position: absolute;
    top: -16px; /* Adjust top position as needed */
    left: 50%; /* Adjust left position as needed */
    transform: translateX(-50%);
    background-color: #3e7a63;
    font-family: 'Inter';
    color: #efe8df;
    padding: 5px 24px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 300;
    border-radius: 10px;
  }

  .slick-slider-container-home .bottom-text-overlay {
    position: absolute;
    bottom: -16px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #c56b48;
    font-family: 'Inter';
    color: #efe8df;
    padding: 5px 30px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 10px;
    text-align: center;
    z-index: 9999;
  }
  .slick-slider-container-home .slick-center > div > div img {
    height: 227px;
    width: 300px;
    border-radius: 10px;
  }

  .slick-slider-container-home .slick-slide:not(.slick-center) > div > div img {
    height: 91px;
    width: 92px;
  }
}


.slick-slider-container-home-mobile .slider-item-mobile {
  position: relative;
  margin-top: 32px;
}

.slick-slider-container-home-mobile .text-overlay-mobile {
  position: absolute;
  top: -16px; /* Adjust top position as needed */
  left: 50%; /* Adjust left position as needed */
  transform: translateX(-50%);
  background-color: #3e7a63;
  font-family: 'Inter';
  color: #efe8df;
  padding: 5px 24px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 300;
  border-radius: 10px;
}

.slick-slider-container-home-mobile .bottom-text-overlay-mobile {
  position: absolute;
    bottom: -16px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #c56b48;
    font-family: 'Inter';
    color: #efe8df;
    padding: 5px 30px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 10px;
    text-align: center;
    z-index: 9999;
}

.slick-slider-container-member-say-mobile .slick-slide img {
  display: inline;
}



@media (max-width:639px) {
  .slick-slider-container-plans-mobile .slick-slide:not(.slick-center) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 640px) and  (max-width:1100px) {
  .slick-slider-container-plans-mobile .slick-center {
    padding-left: 10px;
    padding-right: 1px;
  }
}
@media (min-width: 640px) and  (max-width:1100px) {
  .slick-slider-container-plans-mobile .slick-active:not(.slick-center) {
    padding-right: 10px;
  }
}
.slick-slider-container-plans-mobile .slick-slider .slick-dots {
 bottom: -37px;
}

@media (min-width: 640px) and  (max-width:1100px) {
  .slick-slider-other-member-say-tablet .slick-center {
    padding-left: 5px;
  }
}
@media (min-width: 640px) and  (max-width:1100px) {
  .slick-slider-other-member-say-tablet .slick-active:not(.slick-center) {
    padding-right: 5px;
  }
}

.rs-picker-toggle-wrapper > div input {
  font-size: 18px;
  font-family: 'Inter';
  font-weight: 300;
  line-height: 21.6px;
}

.Toastify__toast-container .Toastify__toast-body {
  border-radius: 8px;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 400;
  color: black;
}

.Toastify__toast-container .Toastify__toast {
  border-radius: 8px;
  background-color: #efe8df;
}

/* Override the background color of the success toast */
.Toastify__close-button {
  display: flex;
  align-items: center;
  color: black;
  margin-top: 16px;
  /* padding-top: 20px; */
}
.Toastify__toast-container .Toastify__close-button svg {
  fill: black !important;
}

.Toastify__close-button .Toastify__toast--success svg {
  fill: black;
}

.Toastify__toast--success .Toastify__toast-icon svg {
  fill: #078d45;
}

.p-PaymentDetails-group label {
  font-size: 16px !important;
  font-weight: 600 !important;
  color: #4a4a4a !important;
  font-family: 'Inter' !important;
  line-height: 24px !important;
}

.sign-up-button {
  color: #0F0F0F;
  border: 1px solid #E2BA13;
  box-shadow: 0 0 5px #E2BA13, 0 0 5px #E2BA13 inset;
  z-index: 2;
  transition: all 0.3s ease;
  overflow: hidden;
  border-radius: 100%; 
}
.sign-up-button:after {
  position: absolute;
  content: " ";
  z-index: -1;
  top: 0;
  left: 0;
  width: 70px;
  height: 70px;
  transition: all 0.3s ease;
  border-radius: 100%;
}
.sign-up-button:hover {
  color: #000;
}
.sign-up-button:hover:after {
  background: #E2BA13;
  -webkit-transform: scale(2) rotate(180deg);
  transform: scale(2) rotate(180deg);
  box-shadow:  4px 4px 6px 0 rgba(255,255,255, 1),
              -4px -4px 6px 0 rgba(116, 125, 136, .2), 
    inset -4px -4px 6px 0 rgba(255,255,255, 1),
    inset 4px 4px 6px 0 rgba(116, 125, 136, .3);
}



:root {
  --clr-neon: #E2BA13;
  --clr-bg: #E2BA13;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}



.neon-button {
  border: var(--clr-neon) 0.125em solid;
  /* text-shadow: 0 0 0.125em rgba(255, 255, 255, 0.3), 0 0 0.45em currentColor; */
  box-shadow: inset 0 0 0.5em 0 var(--clr-neon), 0 0 0.5em 0 var(--clr-neon);
  position: relative;
}

.neon-button::before {
  pointer-events: none;
  /* content: ""; */
  position: absolute;
  background: var(--clr-neon);
  top: 120%;
  left: 0;
  width: 100%;
  height: 100%;
  transform: perspective(1em) rotateX(40deg) scale(1, 0.35);
  filter: blur(1em);
  opacity: 0.7;
}

.neon-button::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 0 2em 0.5em var(--clr-neon);
  opacity: 0;
  background-color: var(--clr-neon);
  z-index: -1;
  transition: opacity 100ms linear;
  border-radius: 100%;
}

.neon-button:hover,
.neon-button:focus {
  text-shadow: none;
  border-radius: 100%;
}

.neon-button:hover::before,
.neon-button:focus::before {
  opacity: 1;
  border-radius: 100%;
}

.neon-button:hover::after,
.neon-button:focus::after {
  border-radius: 100%;
  opacity: 1;
}

.rs-picker-daterange .rs-input-group.rs-input-group-inside .rs-input {
  background-color: #EFE8DF;
}
@media (min-width: 640px) and  (max-width:1100px) {
  .rs-picker-daterange .rs-input-group.rs-input-group-inside .rs-input {
  font-size: 14px;
  font-family: Inter;
  font-weight: 300;
  line-height: 16.8px;
  }
}
@media (max-width: 640px) {
  .rs-picker-daterange .rs-input-group.rs-input-group-inside .rs-input {
  font-size: 14px;
  font-family: Inter;
  font-weight: 300;
  line-height: 16.8px;
  }
}

@media (min-width: 320px) and  (max-width:639px) {

  .rs-picker-popup-daterange{
    left: 20px !important;
    width: 320px !important;
  
  }
  
  .rs-picker-popup-daterange .rs-picker-daterange-panel .rs-stack .rs-stack-item .rs-picker-daterange-content .rs-picker-daterange-calendar-group {
    flex-direction: column !important;
    height: 100% !important;
    min-width: auto !important;
  }
  
  
  
  /* rs-picker-daterange-calendar-group */
  /* .rs-picker-daterange-panel {
  max-width: 400px;
  } */
}

@media (min-width: 1101px){
/* Slider css for booking page section start  */

.slick-slider-container-booking-vip .slider-item {
  display: flex;
  justify-content: center;
}

.slick-slider-container-booking-vip .slick-slider {
 padding: 0px 35px;
}

.slick-prev {
  width: 60px !important;
  height: 25px !important;
  z-index: 50;
}

.slick-next {
  width: 60px !important;
  height: 25px !important;
  z-index: 50;
}


.slick-slider-container-booking-vip .slick-center  {
  display: block;
  width: 362px !important;
  height: 460px;
}

@media (min-width: 1025px) and (max-width:1440px){
  .slick-slider-container-booking-vip .slick-slider {
    padding: 0px 27px;
   }
  .slick-slider-container-booking-vip .slick-center  {
    display: block;
    width: 246px !important;
    height: 415px;
  }
}



.slick-slider-container-booking-vip .slick-center > div {
  height: 100%;
}

.slick-slider-container-booking-vip .slick-center > div > div img {
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.slick-slider-container-booking-vip .slick-slide:not(.slick-center) > div > div img {
  width: 129px;
  height: 265px;
  border-radius: 10px;
  object-fit: cover;
}
.slick-slider-container-booking-vip .slick-active:not(.slick-center) {
  /* width: 200px !important; */
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.slick-slider-container-booking-vip .slick-track {
  display: flex;
  align-items: center;
}
}

@media (min-width: 640px) and  (max-width:1100px) {


.slick-slider-container-booking-vip .slider-item {
  display: flex;
  justify-content: center;
}

.slick-slider-container-booking-vip .slick-slider {
 padding: 0px 35px;
}

.slick-prev {
  width: 60px !important;
  height: 25px !important;
  z-index: 50;
}

.slick-next {
  width: 60px !important;
  height: 25px !important;
  z-index: 50;
}


.slick-slider-container-booking-vip .slick-center  {
  display: block;
  width: 289px !important;
  height: 389px;
}

.slick-slider-container-booking-vip .slick-center > div {
  height: 100%;
}

.slick-slider-container-booking-vip .slick-center > div > div img {
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.slick-slider-container-booking-vip .slick-slide:not(.slick-center) > div > div img {
  width: 103px;
  height: 224px;
  border-radius: 10px;
  object-fit: cover;
}
.slick-slider-container-booking-vip .slick-active:not(.slick-center) {
  /* width: 150px !important; */
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.slick-slider-container-booking-vip .slick-slide > div .slick-left {
  display: flex;
  justify-content: end !important;
  padding-right: 20px;
}

.slick-slider-container-booking-vip .slick-slide > div .slick-right {
  display: flex;
  justify-content: start !important;
  padding-left: 20px;
}

.slick-slider-container-booking-vip .slick-track {
  display: flex;
  align-items: center;
}

}

@media (min-width: 320px) and  (max-width: 640px) {

  .slick-slider-container-booking-vip .slider-item {
    display: flex;
    justify-content: center;
  }
  
  .slick-slider-container-booking-vip .slick-slider {
   padding: 0px 20px;
  }
  
  .slick-prev {
    width: 60px !important;
    height: 25px !important;
    z-index: 50;
  }
  
  .slick-next {
    width: 60px !important;
    height: 25px !important;
    z-index: 50;
  }
  
  
  .slick-slider-container-booking-vip .slick-center  {
    display: block;
    width: 141px !important;
    height: 217px;
  }
  
  .slick-slider-container-booking-vip .slick-center > div {
    height: 100%;
  }
  
  .slick-slider-container-booking-vip .slick-center > div > div img {
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
  }
  
  .slick-slider-container-booking-vip .slick-slide:not(.slick-center) > div > div img {
    width: 50px;
    height: 125px;
    border-radius: 10px;
    object-fit: cover;
  }
  
  .slick-slider-container-booking-vip .slick-track {
    display: flex;
    align-items: center;
  }
  
  }

  @media (min-width: 533px) and  (max-width: 640px) {

    .slick-slider-container-booking-vip .slick-active:not(.slick-center) {
      width: 200px !important;
      height: 100%;
      border-radius: 10px;
      object-fit: cover;
    }
  }
  @media (min-width: 420px) and  (max-width: 533px) {

    .slick-slider-container-booking-vip .slick-active:not(.slick-center) {
      /* width: 150px !important; */
      height: 100%;
      border-radius: 10px;
      object-fit: cover;
    }

    .slick-slider-container-booking-vip .slick-slide > div .slick-left {
      display: flex;
      justify-content: end !important;
      padding-right: 8px;
    }
    
    .slick-slider-container-booking-vip .slick-slide > div .slick-right {
      display: flex;
      justify-content: start !important;
      padding-left: 8px;
    }
  }

  @media (min-width: 320px) and  (max-width: 420px) {

    .slick-slider-container-booking-vip .slick-active:not(.slick-center) {
      width: 90px !important;
      height: 100%;
      border-radius: 10px;
      object-fit: cover;
    }
  }
  @media (min-width: 320px) and  (max-width: 420px) {

    .slick-slider-container-booking-exclusive-mobile .slick-slider .slick-list .slick-track {
      /* width: 113px !important; */
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      /* border-radius: 10px; */
      object-fit: cover;
    }
  }
  @media (min-width: 800px) {


    .slick-slider-container-booking-exclusive .slick-track {
      display:flex;
      align-items: center;
    }
    .slick-slider-container-booking-exclusive .slick-list {
      margin: 0px 60px;
    }
  
    .slick-slider-container-booking-exclusive .slick-active  {
      /* display: block; */
      display: flex;
      align-items: center;
      width: 362px !important;
      /* height: 460px; */
    }
    .slick-slider-container-booking-exclusive .slick-slide  {
      width: 362px !important;
    }
  }

  @media (min-width: 640px) and (max-width: 800px) {


    .slick-slider-container-booking-exclusive .slick-track {
      display:flex;
      align-items: center;
    }
    .slick-slider-container-booking-exclusive .slick-list {
      margin: 0px 60px;
    }
  
    .slick-slider-container-booking-exclusive .slick-active  {
      /* display: block; */
      display: flex;
      align-items: center;
      width: 462px !important;
      /* height: 460px; */
    }
    .slick-slider-container-booking-exclusive .slick-slide  {
      width: 462px !important;
    }
  }