.button {
  display: flex;
  justify-content: center;
  align-items: center;

  &-xs {
    border-radius: 4px;
    min-width: 72px !important;
    padding: 7px 14px;
    height: 34px;
  }

  &-s {
    border-radius: 4px;
    padding: 10px 20px;
    min-width: 84px !important;
    height: 40px;
  }

  &-m {
    border-radius: 6px;
    padding: 14px 24px;
    min-width: 96px !important;
    height: 48px;
  }
}

.primary {
  background-color: #4A4A4A !important;
  border: 1px solid #4A4A4A !important;
  color: #ffffff !important;
  font-size: 14px !important;
  font-family: 'Inter';
  font-weight: 500 !important;
}

.onlyBorder {
  border: 1px solid #4A4A4A !important;
  color: #4A4A4A !important;
  font-size: 14px !important;
  font-family: 'Inter';
  font-weight: 500 !important;
}

.secondary {
  background-color: #4228b2 !important;
  border: 1px solid #4228b2 !important;
  color: #ffffff;
  font-size: 14px;
  font-family: 'Inter';
  font-weight: 500;
}

.ghost {
  background-color: #f0f4f2;
  border: 1px solid #f0f4f2;
  color: #000000;
  font-size: 14px;
  font-family: 'Inter';
  font-weight: 500;
}
.yellow {
  background-color: #E2BA13 !important;
  border: 1px solid #E2BA13;
  color: #000000;
  font-size: 14px;
  font-family: 'Inter';
  font-weight: 500;
}
.orange {
  background-color: #C56B48 !important;
  border: 1px solid #C56B48;
  color: #EFE8DF;
  font-size: 14px;
  font-family: 'Inter';
  font-weight: 500;
}

.gray {
  background-color: #00000014 !important;
  border: 1px solid #f0f4f2;
  color: #000000;
  font-size: 14px;
  font-family: 'Inter';
  font-weight: 500;
}

.disabled {
  // background-color: #f4f5f5;
  // border: 1px solid #f4f5f5;
  // color: #a7aaa9;
  // font-size: 14px;
  font-family: 'Inter';// 
  font-weight: 500;

  background-color: #00000014 !important;
  border: 1px solid #f0f4f2;
  color: #000000;
  font-size: 14px;
  font-family: 'Inter';
  font-weight: 500;
  cursor: not-allowed !important;
}

.error {
  background-color: #fbeff1;
  color: #ea174a;
  font-size: 14px;
  font-family: 'Inter';
  font-weight: 500;
}

.white {
  @extend .ghost;
  background-color: #F0F4F2 !important;
}

.primary:hover {
  // background-color: darken(#000000, 10%) !important;
  border-color: darken(#4A4A4A, 10%) !important;
}

.secondary:hover {
  background-color: darken(#4228b2, 10%) !important;
  border-color: darken(#4228b2, 10%) !important;
}

.ghost:hover {
  background-color: darken(#f0f4f2, 10%);
  border-color: darken(#f0f4f2, 10%);
}

.gray:hover {
  background-color: darken(#00000014, 10%) !important;
  border-color: darken(#f0f4f2, 10%);
}

.error:hover {
  background-color: darken(#fbeff1, 10%);
  color: darken(#ea174a, 10%);
}

.white:hover {
  background-color: darken(#F0F4F2, 10%) !important;
}


.icon,
.button-label {
  display: inline-flex;
  align-items: center;
  height: 100%;
}

.icon-start {
  margin-right: 10px;
}

.icon-end {
  margin-left: 10px;
}
