$breakpoint-desktop: 850px;
$breakpoint-mobile: 390px;

$duration: 30s;


.header-mobile,
.header-desktop {
  display: none;
}

@media (min-width: $breakpoint-desktop) {
  .header-desktop {
    display: flex;
    height: 104px;
    width: 100%;
    max-width: 1170px;
    align-items: center;
    margin: auto;
    padding: 0 25px;
    & .logo-img {
      height: 28px;
      margin-right: 48px;
    }
    & .navigation {
      display: flex;
      gap: 0 24px;
      & button{
        border: 1px solid transparent;
      }
      & button:hover{
        border-bottom: 1px solid black;
      }
    }
    & .auth-btns {
      display: flex;
      gap: 0 24px;
      align-items: center;
      margin-left: auto;
      & .signup-btn {
        width: 118px;
        padding: 14px 32px;
        border-radius: 6px;
        background-color: #F0F4F2;
      }
      & button {
        color: black;
        font-weight: 500;
        font-size: 15px;
        height: fit-content;
      }
    }
  }
}

@media (max-width: $breakpoint-desktop) {
  .header-mobile {
    display: flex;
    justify-content: space-between;
    padding: 12px 25px;
    align-items: center;
    & .logo-img {
      height: 24px;
    }
    & .signup-btn {
      color: black;
      height: 40px;
      width: 100px;
      border-radius: 6px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      background-color: rgba(0, 0, 0, 0.1);
    }
    & .open-menu {
      margin-left: 25px;
    }
    & .mobile-menu {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100vh;
      width: 100vw;
      background: #fff;
      z-index: 2;
      padding: 20px 24px 40px 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 48px;
      &-header {
        display: flex;
        justify-content: space-between;
      }
      &-content {
        height: 70vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      &-nav {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
        button {
          font-weight: 700;
        }
      }
      &-buttons {
        display: flex;
        justify-content: center;
        gap: 40px;
        padding: 24px 0;
        border-top: 1px solid #F0F1F1;
        border-bottom: 1px solid #F0F1F1;
      }
      &-footer {
        display: flex;
        align-items: center;
        gap: 40px;
        & .primary-container {
          width: 100%;
          max-width: 183px;
          p {
            color: #7E8180;
            font-size: 14px;
            line-height: 24px;
            padding-right: 24px;
            margin: 0;
          }
        }
        & .social-media-container{
          display: flex;
          gap: 8px;
          & a {
            height: 48px;
            width: 48px;
            border-radius: 8px;
            border: 1px solid #F0F1F1;
            & img{
              height: 48px;
              padding: 8px;
            }
          }
        }
      }
    }
  }
}


.black-block{
  width:100%;
  white-space:nowrap;
  overflow:hidden;
  // background-color: #C56B48;
  height: 52px;
  // position: fixed;
  // bottom: 0;
  z-index: 50;
  display: flex;
  align-items: center;
  div{
    display: flex;
    align-items: center;
    gap: 24px;
    margin-right: 24px;
  }
  .ticker-wrapper__first-half{
    display: flex;
    height: 100%;
    gap: 24px;
    align-items: center;
    white-space: nowrap;
    -webkit-animation: scroll $duration infinite linear;
    -moz-animation: scroll $duration infinite linear;
    animation: scroll $duration infinite linear;
    // margin: 8px auto;
  }
  .pattern{
    display: flex;
    height: 100%;
    gap: 24px;
    p{
      font-size: 26px;
      display: flex;
      align-items: center;
      font-weight: 500;
      line-height: 16px;
      color: white;
      margin: 0;
      text-transform: uppercase;
      // padding-top: 13.5px;
      // padding-bottom: 13.5px;

    }
    img{
      width: 24px;
    }
  }
}

@keyframes scroll{
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-100%, 0);
  }
}